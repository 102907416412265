

.react-add-to-calendar {
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    position: relative;
    display: inline-block;
    margin: 0 auto;
    /* font-family: 'Aktiv Grotesk'; */
}
.react-add-to-calendar__wrapper {
    zoom: 1;
    cursor: pointer;
}
.react-add-to-calendar__button {
    /* padding: 10px; */
    display: block;
    border-radius: 3px;
    position: relative;
    /* color: #0073E7 !important; */
    /* padding-left: 20px; */
    padding-bottom: 0px;
}

.react-add-to-calendar__button--light {
    background-color: #fff;
}
.react-add-to-calendar__icon--right {
    padding-left: 5px;
}
.react-add-to-calendar__icon--left {
    padding-right: 5px;
}
.react-add-to-calendar__dropdown {
    position: absolute;
    z-index: 1;
    bottom: 22px;
    border-radius: 10px;
    left: -10px;
    width: 125px;
    padding: 10px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);;
    border: 1px solid #a8a8a8;
    background-color: #fff;
    text-align: left;
}
.react-add-to-calendar__dropdown ul {
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.react-add-to-calendar__dropdown ul li  {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    transition: 250ms linear;
}
.react-add-to-calendar__dropdown ul li:hover {
    background-color: cornsilk;
    height: 30px;
    width: 100%;
}
.react-add-to-calendar__dropdown ul li a {
    color: #000;
    text-decoration: none;
}
.react-add-to-calendar__dropdown ul li a i {
    padding-right: 10px;
}
