/* @font-face {
  font-family: "Aktiv Grotesk";
  font-weight: 500;
  src: url("Assets/fonts/aktivgrotesk_md.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Aktiv Grotesk";
  font-weight: 400;
  src: url("Assets/fonts/aktivgrotesk_rg.ttf") format("truetype");
} */

a {
  text-decoration: none;
  color: #0073E7
}
/* 

@font-face {
  font-family: "Aktiv Grotesk";
  font-weight: 500;
  src: url(https://eventtool-content.s3.amazonaws.com/aktiv_ttf_6567.ttf) format("truetype");
}
@font-face {
  font-family: "Aktiv Grotesk";
  font-weight: 400;
  src: url(https://eventtool-content.s3.amazonaws.com/aktiv_ttf_8613.ttf) format("truetype");
} */
