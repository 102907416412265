body .slick-next
{
    right: 25px !important;
    /* background: transparent linear-gradient(to left, #030303 0%, #00000000 100%) 0% 0% no-repeat padding-box;; */
    z-index: 12;

}
body .slick-prev
{
     left: 25px !important;
    /* background: transparent linear-gradient(to left, #030303 0%, #00000000 100%) 0% 0% no-repeat padding-box;; */
    z-index: 12;
    
}
body .slick-list
{
    padding-left: 24px;
   

}
/* body .slick-current
{
    transform: scale(1.3);
    transition: 300ms ease;
    z-index: 100;
    margin-right: 10px !important;
    /* max-width: 495 !important; 
} 
*/
body .slick-track {
    display: flex !important;
    align-items: flex-start !important;
    margin-left: 20px !important;
    width: 100% !important;
}
body .slide-active {
    display: flex !important;
    justify-content: center;
    max-height: 220 !important;
}
body .slick-slide{
    width: 450px !important;
    margin: 0 12px;
    border: none !important;
}